import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from '../components/seo'
import styles from './works.module.scss'

const ResearchPage = () => {
    // Fetch the Markdown post data
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    keywords
                }
            }
            allMarkdownRemark 
            (sort: { fields: [frontmatter___date], order: DESC })
            {
                edges {
                    node {
                        frontmatter {
                            title
                            date
                            tags
                            image: featured {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 2000, 
                                        maxHeight: 1000, 
                                        quality: 100,
                                        cropFocus: CENTER) 
                                    {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Works" keywords={data.site.siteMetadata.keywords} />

            {data.allMarkdownRemark.edges.map((edge) => {
                if (edge.node.frontmatter.tags[1] == "Research") {
                    return (
                        <div className={styles.link}>
                            <Link to={`/${edge.node.fields.slug}`}>
                                <div className={styles.featured_work_item}>
                                    <div>
                                        <Img fluid={edge.node.frontmatter.image.childImageSharp.fluid} alt="mindbuffer_logo" />
                                    </div>

                                    <div className={styles.titles}>
                                        <div className={styles.section}>
                                            <h1>{edge.node.frontmatter.title}</h1>
                                        </div>
                                        <div className={styles.section}>
                                            <h2>{edge.node.frontmatter.tags[0]}</h2>
                                        </div>
                                    </div>

                                </div>
                            </Link>
                        </div>
                    )
                }
            })}
        </Layout>
    )
}

export default ResearchPage